<template>
  <mahasiswa-asing-modal
    v-if="modalActive"
    :class="{ 'is-active': modalActive }"
    :mahasiswa="mahasiswa"
    v-on:close-modal="closeModal"
  />

  <div class="columns">
    <div class="column">{{ mahasiswa.fakultas }}</div>
    <div class="column">{{ mahasiswa.jurusan }}</div>
    <div class="column">{{ mahasiswa.nama }}</div>
    <div class="column is-1">
      <i-eye class="iicon" @click="showModal" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import MahasiswaAsingModal from "./MahasiswaAsingModal";
import IEye from "@/assets/svg/eye.svg";

export default {
  name: "MahasiswaAsingItem",
  components: { MahasiswaAsingModal, IEye },
  props: ["mahasiswa"],
  setup() {
    let modalActive = ref(false);

    const showModal = () => {
      console.info("modalActive", modalActive);
      modalActive.value = true;
    };

    const closeModal = () => {
      modalActive.value = !modalActive.value;
    };

    return { showModal, modalActive, closeModal };
  },
};
</script>

<style lang="scss" scoped>
.columns {
  @media (max-width: 769px) {
    border: 1px solid $ketiga;
    margin: 2px 0;
  }
  .column {
  }
}
</style>
