<template>
  <div id="app">
    <layout/>
  </div>
</template>

<script>
import Layout from "./components/layouts/Layout";

export default {
  name: "App",
  components: {Layout},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.iicon {
  transition: 500ms ease all;
  cursor: pointer;
  color: $kedua;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $pertama;
  }
}

a {
  color: $pertama;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $kedua;
  }
}
</style>
