<template>
  <div class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ mahasiswa.nama }}</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <div class="ml-3">
          <div class="columns">
            <div class="cloumn column-header">Nama</div>
            <div class="cloumn column-isi">{{ mahasiswa.nama }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Fakultas</div>
            <div class="cloumn column-isi">{{ mahasiswa.fakultas }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Prodi</div>
            <div class="cloumn column-isi">{{ mahasiswa.jurusan }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Angkatan</div>
            <div class="cloumn column-isi">{{ mahasiswa.tahunangkatan }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Program</div>
            <div class="cloumn column-isi">{{ mahasiswa.program }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Negara</div>
            <div class="cloumn column-isi">{{ mahasiswa.negara }}</div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="closeModal">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "MahasiswaAsingModal",
  props: ["mahasiswa"],
  setup(_, context) {
    const closeModal = () => {
      context.emit("close-modal");
    };

    return { closeModal };
  },
};
</script>

<style lang="scss" scoped>
.modal {
  .modal-card {
    .modal-card-head,
    .modal-card-foot {
      background-color: $ketiga;

      button {
        background-color: $kelima;
        color: $keempat;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: rgba(8, 199, 192, 0.8);
        }
      }
    }

    .modal-card-body {
      .column-header {
        width: 20%;
      }
      .column-isi {
        width: 80%;
        margin-left: 5px;
      }
    }
  }
}
</style>
