import axios from "axios";

// initial state
const state = {
  mahasiswaAsings: [],
  mahasiswaAsing: null,
  mahasiswaAsingPagingPage: 0,
  mahasiswaAsingPagingSize: 0,
  mahasiswaAsingPagingTotalElements: 0,
  mahasiswaAsingPagingTotalPages: 0,
};

// getters
const getters = {
  mahasiswaAsingList: (state) => state.mahasiswaAsings,
  mahasiswaAsing: (state) => state.mahasiswaAsing,
  mahasiswaAsingPagingPage: (state) => state.mahasiswaAsingPagingPage,
  mahasiswaAsingPagingSize: (state) => state.mahasiswaAsingPagingSize,
  mahasiswaAsingPagingTotalElements: (state) =>
    state.mahasiswaAsingPagingTotalElements,
  mahasiswaAsingPagingTotalPages: (state) =>
    state.mahasiswaAsingPagingTotalPages,
};

// actions
const actions = {
  async fetchAllMahasiswaAsings({ commit }, query = null) {
    let page = "";
    let search = "";
    if (query !== null) {
      page = query.page;
      search = query.search;

      console.info("query", query);
    }

    //console.info('query', query.page)

    let url = `${process.env.VUE_APP_API_URL}kerjasama/mahasiswa-asing/filter`;
    if (search == null) {
      url = `${url}?page=${page}`;
    } else {
      url = `${process.env.VUE_APP_API_URL}kerjasama/mahasiswa-asing/filter?search=${search}&page=${page}`;
    }

    //console.info('url', url)

    await axios
      .get(url)
      .then((res) => {
        const mahasiswaAsings = res.data.data;
        //console.info('mahasiswaAsings', mahasiswaAsings) // bener
        commit("setMahasiswaAsings", mahasiswaAsings);

        const mahasiswaAsingPagings = res.data.data.pageable;
        //console.info('mahasiswaAsingPagings', mahasiswaAsingPagings)
        commit("setMahasiswaAsingPaging", mahasiswaAsingPagings);
      })
      .catch((err) => {
        console.info("error", err);
      });
  },
};

// mutations
const mutations = {
  setMahasiswaAsings: (state, mahasiswa) => {
    state.mahasiswaAsings = mahasiswa;
    //console.info('m', state.mahasiswaAsings)
  },
  setMahasiswaAsingPaging: (state, pagings) => {
    //state.mahasiswaAsingPaging = pagings;
    state.mahasiswaAsingPagingPage = pagings.page;
    state.mahasiswaAsingPagingSize = pagings.size;
    state.mahasiswaAsingPagingTotalElements = pagings.totalElements;
    state.mahasiswaAsingPagingTotalPages = pagings.totalPages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
