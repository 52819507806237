<template>
  <Suspense>
    <template #default>
      <section class="hero is-small box">
        <h1 class="title">Kerjasama Berdasarkan Jenis</h1>
        <div class="level">
          <pie-chart class="kiri" :data="dataJenis" :donut="true" legend="bottom"></pie-chart>

          <pie-chart class="kanan" :data="dataLevel" :donut="true" legend="bottom"></pie-chart>
        </div>
      </section>
    </template>
    <template #fallback>
      Chart is loading...
    </template>
  </Suspense>
</template>

<script>
import {onMounted, ref} from "vue";
import axios from "axios";

export default {
  name: "ChartKerjasamaPie",
  setup() {
    let dataJenis = ref([]);
    let dataLevel = ref([]);

    onMounted(() => {
      getDataJenis();
      getDataLevel();
    });

    const getDataJenis = async () => {
      const url = `${process.env.VUE_APP_API_URL}kerjasama/chart-kerjasama-by-jenis`;
      await axios
          .get(url)
          .then((res) => {
            const datas = res.data.data;
            dataJenis.value = datas.map(({nama, jumlah}) => [nama, jumlah]);
          })
          .catch((err) => {
            console.info("error", err);
          });
    }

    const getDataLevel = async () => {
      const url = `${process.env.VUE_APP_API_URL}kerjasama/chart-kerjasama-by-level`;
      await axios
          .get(url)
          .then((res) => {
            const datas = res.data.data;
            dataLevel.value = datas.map(({nama, jumlah}) => [nama, jumlah]);
          })
          .catch((err) => {
            console.info("error", err);
          });
    }

    return {dataJenis, dataLevel}
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-left: 5px;

  @media (max-width: 769px) {
    font-size: larger;
  }
}

.kiri {
  margin-left: 5px;
  background-color: $keempat;
  width: 50%;
}

.kanan {
  margin-left: 5px;
  background-color: $keempat;
  width: 50%;
}
</style>
