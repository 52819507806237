import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import MahasiswaAsing from "@/views/MahasiswaAsing";
import Kerjasama from "@/views/Kerjasama";
import About from "@/views/About";
import NotFound from "@/views/NotFound";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/kerjasama",
    name: "Kerjasama",
    component: Kerjasama,
    meta: {
      title: "Mitra Kerjasama",
    },
  },
  {
    path: "/mahasiswa-asing",
    name: "MahasiswaAsing",
    component: MahasiswaAsing,
    meta: {
      title: "Mahasiswa Asing",
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: {
      title: "Not Found",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Aplikasi Kerjasama`;
  next();
});

export default router;
