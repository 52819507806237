<template>
  <suspense>
    <template #default>
      <section class="section is-large p-4">
        <h1 class="title">Cari Mitra Kerjasama</h1>
        <h2 class="subtitle">
          Universitas Islam Negeri Syarif Hidayatullah Jakarta.
        </h2>

        <div class="content-form">
          <div class="level search">
            <input
                v-model="kata"
                id="kata"
                class="input is-medium input-search"
                type="text"
                placeholder="Masukkan mitra kerjasama"
                @keyup.enter="cariKerjasama"
            />

            <button
                v-if="!isShowHide"
                class="button is-medium button-search"
                @click="cariKerjasama"
            >
              Cari
            </button>
          </div>
          <div class="search-full level">
            <span class="tag search-toggle" @click="searchFull">{{
                showHide
              }}</span>

            <div class="search-toggle-form" v-if="isShowHide">
              <div class="level">
                <div class="select is-medium">
                  <select
                      v-model="jenisKerjasamaSelected"
                      id="jenisKerjasamaSelected"
                  >
                    <option
                        v-for="jk in jenisKerjasama"
                        :value="jk.id"
                        :key="jk.id"
                    >
                      {{ jk.nama }}
                    </option>
                  </select>
                </div>

                <div class="select is-medium">
                  <select
                      v-model="dokumenKerjasamaSelected"
                      id="dokumenKerjasamaSelected"
                  >
                    <option
                        v-for="dk in dokumenKerjasama"
                        :value="dk.id"
                        :key="dk.id"
                    >
                      {{ dk.nama }}
                    </option>
                  </select>
                </div>

                <div class="select is-medium">
                  <select
                      v-model="kategoriKerjasamaSelected"
                      id="kategoriKerjasamaSelected"
                  >
                    <option
                        v-for="kk in kategoriKerjasama"
                        :value="kk.id"
                        :key="kk.id"
                    >
                      {{ kk.nama }}
                    </option>
                  </select>
                </div>

                <div class="select is-medium">
                  <select
                      v-model="levelKerjasamaSelected"
                      id="levelKerjasamaSelected"
                  >
                    <option
                        v-for="lk in levelKerjasama"
                        :value="lk.id"
                        :key="lk.id"
                    >
                      {{ lk.nama }}
                    </option>
                  </select>
                </div>
              </div>

              <button
                  v-if="isShowHide"
                  class="button is-medium search-toggle-button"
                  @click="cariKerjasama"
              >
                Cari
              </button>
            </div>
          </div>
        </div>

        <div class="content-data" v-if="isData">
          <div class="columns">
            <div class="column">Kategori</div>
            <div class="column">Mitra</div>
            <div class="column">Jenis</div>
            <div class="column">Negara</div>
            <div class="column is-1">Detail</div>
          </div>

          <kerjasama-item
              :kerjasama="item"
              v-for="(item, index) in data.list"
              :key="index"
          />
        </div>

        <div class="content-data" v-else>
          <p class="has-text-centered warning">
            Data tidak ditemukan atau Gunakan pencarian yang lain.
          </p>
        </div>

        <div class="content-paging" v-if="isData">
          <nav
              class="pagination is-right"
              role="navigation"
              aria-label="pagination"
          >
            <button
                class="pagination-previous paging-button"
                @click="previousPage"
                :disabled="page === 0"
            >
              Previous
            </button>
            <button
                class="pagination-next paging-button"
                @click="nextPage"
                :disabled="page === totalPages"
            >
              Next page
            </button>
          </nav>
        </div>
      </section>
    </template>
    <template #fallback>
      <div class="pageloader is-active"><span class="title">Loading</span></div>
    </template>
  </suspense>
</template>

<script>
import {computed, onMounted, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import KerjasamaItem from "../components/kerjsama/KerjasamaItem";
import {useRoute} from "vue-router";

export default {
  name: "Kerjasama",
  components: {KerjasamaItem},
  setup() {
    const route = useRoute();
    const store = useStore();

    onMounted(async () => {
      if (route.query.search !== null) {
        const kata = route.query.search;
        query.page = 0;
        query.kata = kata;
        await store.dispatch("fetchAllKerjasamas", query);
        cekData();
      } else {
        query.page = 0;
        query.kata = null;
        await store.dispatch("fetchAllKerjasamas", query);
        cekData();
      }
    });

    const data = computed(() => store.getters.kerjasamasList);

    const query = reactive({
      kata: "",
      showHide: "Show",
      isShowHide: false,
      //datas: computed(() => store.getters.kerjasamasList),
      isData: data.value.list === null,
      page: 0,
      totalPages: computed(() => store.getters.kerjasamaPagingTotalPages - 1),
      jenisKerjasama: computed(() => store.getters.jenisKerjasamaList),
      jenisKerjasamaSelected: 0,
      dokumenKerjasama: computed(() => store.getters.dokumenKerjasamaList),
      dokumenKerjasamaSelected: 0,
      kategoriKerjasama: computed(() => store.getters.kategoriKerjasamaList),
      kategoriKerjasamaSelected: 0,
      levelKerjasama: computed(() => store.getters.levelKerjasamaList),
      levelKerjasamaSelected: 0,
    });

    if (route.query.search !== null) {
      const kata = route.query.search;
      query.kata = kata;
      handleSearch();
    }

    const cariKerjasama = () => {
      handleSearch();
      cekData();
    };

    function cekData() {
      if (data.value.list.length === 0) {
        query.isData = false;
      } else {
        query.isData = true;
      }
    }

    function handleSearch() {
      //query.page = 0;
      store.dispatch("fetchAllKerjasamas", query);
    }

    const searchFull = () => {
      if (query.isShowHide) {
        query.showHide = "Show";
      } else {
        query.showHide = "Hide";
      }
      query.isShowHide = !query.isShowHide;
    };

    const previousPage = () => {
      query.page -= 1;
      store.dispatch("fetchAllKerjasamas", query);
    };

    const nextPage = () => {
      query.page += 1;
      store.dispatch("fetchAllKerjasamas", query);
    };

    return {
      ...toRefs(query),
      searchFull,
      cariKerjasama,
      data,
      previousPage,
      nextPage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma";
@import "~bulma-pageloader";

@media (max-width: 769px) {
  .title {
    font-size: larger;
  }
  .subtitle{
    font-size: small;
  }
}

.content-form {
  .search {
    .input-search {
    }

    .button-search {
      background-color: $kelima;
      color: $keempat;
      margin-left: 5px;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: rgba(8, 199, 192, 0.8);
      }
    }

    @media (max-width: 769px) {
      .button-search {
        margin-top: 5px;
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .search-full {
    .search-toggle {
      margin-left: 5px;
      margin-top: 5px;
      background-color: $pertama;
      color: $keempat;
    }

    .search-toggle-form {
      margin-left: 5px;
      @media (max-width: 769px) {
        margin-top: 5px;

        .level .select {
          margin: 2px 0;
        }
      }

      .select {
        margin: 0 2px;
      }

      .search-toggle-button {
        width: 100%;
        background-color: $kelima;
        color: $keempat;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: rgba(8, 199, 192, 0.8);
        }
      }
    }
  }
}

.content-data {
  margin-top: 20px;

  .columns {
    .column {
      background-color: $ketiga;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .warning {
    color: $kelima;
  }
}

.content-paging {
  margin-top: 20px;

  .paging-button {
    background-color: $kelima;
    color: $keempat;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: rgba(8, 199, 192, 0.8);
    }
  }
}
</style>
