// initial state
import axios from "axios";

const state = {
  jenisKerjasama: [
    { id: 0, nama: "Jenis Kerjasama" },
    { id: 1, nama: "Dalam Negeri" },
    { id: 2, nama: "Luar Negeri" },
  ],
  dokumenKerjasama: [
    { id: 0, nama: "Dokumen Kerjasama" },
    { id: 1, nama: "Nota Kesepahaman" },
    { id: 2, nama: "Perjanjian Kerjasama" },
  ],
  kategoriKerjasama: [
    { id: 0, nama: "Kategori Kerjasama" },
    { id: 1, nama: "Pendidikan dan Pengajaran" },
    { id: 2, nama: "Penelitian" },
    { id: 3, nama: "Pengabdian kepada Masyarakat" },
    { id: 4, nama: "Pendayagunaan Sumber Daya" },
  ],
  levelKerjasama: [
    { id: 0, nama: "Level Kerjasama" },
    { id: 1, nama: "Universitas" },
    { id: 2, nama: "Fakultas" },
    { id: 3, nama: "Lembaga" },
    { id: 4, nama: "UPT" },
  ],
  kerjasamas: [],
  kerjasama: null,
  kerjasamasPagingPage: 0,
  kerjasamasPagingTotalPages: 0,
  statistiks: [],
};

// getters
const getters = {
  jenisKerjasamaList: (state) => state.jenisKerjasama,
  dokumenKerjasamaList: (state) => state.dokumenKerjasama,
  kategoriKerjasamaList: (state) => state.kategoriKerjasama,
  levelKerjasamaList: (state) => state.levelKerjasama,
  kerjasamasList: (state) => state.kerjasamas,
  kerjasamaPagingPage: (state) => state.kerjasamasPagingPage,
  kerjasamaPagingTotalPages: (state) => state.kerjasamasPagingTotalPages,
  statistikList: (state) => state.statistiks,
};

// actions
const actions = {
  async fetchAllKerjasamas({ commit }, query = null) {
    let page = 0;
    let search = "";
    let kategori = 0;
    let jenis = 0;
    let dokumen = 0;
    let level = 0;

    if (query !== null) {
      page = query.page;
      search = query.kata;
      kategori = query.kategoriKerjasamaSelected;
      jenis = query.jenisKerjasamaSelected;
      dokumen = query.dokumenKerjasamaSelected;
      level = query.levelKerjasamaSelected;
    }
    //console.info("query data", query);

    let url = `${process.env.VUE_APP_API_URL}kerjasama`;
    if (search === "") {
      url = `${url}?page=${page}`;
    } else {
      url = `${process.env.VUE_APP_API_URL}kerjasama?search=${search}&jenis=${kategori}&kategori=${jenis}&dokumen=${dokumen}&level=${level}&page=${page}`;
    }
    //console.info("url", url);

    await axios
      .get(url)
      .then((res) => {
        const kerjasamas = res.data.data;
        //console.info("kerjasamas", kerjasamas);
        commit("setKerjasamas", kerjasamas);

        const kerjasamasPagingPages = res.data.data.pageable;
        //console.info('mahasiswaAsingPagings', mahasiswaAsingPagings)
        commit("setKerjasamaPaging", kerjasamasPagingPages);
      })
      .catch((err) => {
        console.info("error", err);
      });
  },

  async fetchStatistiks({ commit }) {
    let url = `${process.env.VUE_APP_API_URL}kerjasama/statistik`;
    await axios
      .get(url)
      .then((res) => {
        const statistiks = res.data.data;
        //console.info("statistiks", statistiks)
        commit("setStatistiks", statistiks);
      })
      .catch((err) => {
        console.info("error", err);
      });
  },
};

// mutations
const mutations = {
  setKerjasamas: (state, kerjasamas) => {
    state.kerjasamas = kerjasamas;
  },
  setKerjasamaPaging: (state, pagings) => {
    state.kerjasamasPagingPage = pagings.page;
    state.kerjasamasPagingTotalPages = pagings.totalPages;
  },
  setStatistiks: (state, statistik) => {
    state.statistiks = statistik;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
