<template>
  <div class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Mitra Kerjasama</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <div class="ml-3">
          <div class="columns">
            <div class="cloumn column-header">Mitra</div>
            <div class="cloumn column-isi">{{ kerjasama.mitra }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Tentang</div>
            <div class="cloumn column-isi">{{ kerjasama.tentang }}</div>
          </div>

          <div class="columns" v-if="kerjasama.nomor">
            <div class="cloumn column-header">Nomor</div>
            <div class="cloumn column-isi">
              {{ kerjasama.nomor === null ? "" : kerjasama.nomor }}
            </div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Jenis</div>
            <div class="cloumn column-isi">{{ kerjasama.jenisKerjasama }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Kategori</div>
            <div class="cloumn column-isi">
              {{ kerjasama.kategoriKerjasama }}
            </div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Negara</div>
            <div class="cloumn column-isi">{{ kerjasama.negara }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Dokumen</div>
            <div class="cloumn column-isi">{{ kerjasama.jenisDokumen }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Level</div>
            <div class="cloumn column-isi">{{ kerjasama.levelKerjasama }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Instansi</div>
            <div class="cloumn column-isi">{{ kerjasama.unitKerja }}</div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Tahun Mulai</div>
            <div class="cloumn column-isi">
              {{ getYear(kerjasama.tanggalMulai) }}
            </div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Tahun Selesai</div>
            <div class="cloumn column-isi">
              {{ getYear(kerjasama.tanggalSelesai) }}
            </div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Jangka Waktu</div>
            <div class="cloumn column-isi">
              {{ getSumYear(kerjasama.tanggalMulai, kerjasama.tanggalSelesai) }}
              Tahun
            </div>
          </div>

          <div class="columns">
            <div class="cloumn column-header">Lampiran</div>
            <div class="cloumn column-isi">
              <a
                v-for="(url, index) in kerjasama.lampiran"
                :key="index"
                :href="url"
                >Download</a
              >
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="closeModal">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "KerjasamaModal",
  props: ["kerjasama"],
  setup(_, context) {
    const closeModal = () => {
      context.emit("close-modal");
    };

    function getYear(tanggal) {
      const currentDate = new Date(tanggal);
      return currentDate.getFullYear();
    }

    function getSumYear(mulai, selesai) {
      const currentMulai = new Date(mulai);
      const currentSelesai = new Date(selesai);

      let result = currentSelesai.getFullYear() - currentMulai.getFullYear();

      return result == 0 ? 1 : result;
    }

    return { closeModal, getYear, getSumYear };
  },
};
</script>

<style lang="scss" scoped>
.modal {
  .modal-card {
    .modal-card-head,
    .modal-card-foot {
      background-color: $ketiga;

      button {
        background-color: $kelima;
        color: $keempat;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: rgba(8, 199, 192, 0.8);
        }
      }
    }

    .modal-card-body {
      .column-header {
        width: 20%;
      }
      .column-isi {
        width: 80%;
        margin-left: 5px;
      }

      a {
        color: $pertama;

        &:focus {
          outline: none;
        }

        &:hover {
          color: $kedua;
        }
      }
    }
  }
}
</style>
