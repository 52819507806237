<template>
  <Suspense>
    <template #default>
      <section class="hero is-small box">
        <div class="level">
          <h1 class="title">Kerjasama Berdasarkan Tahun</h1>

          <div class="kanan" data-aos="fade-left" data-aos-duration="3000">
            <line-chart :data="data" :colors="['#08c7c0']" xtitle="Tahun" ytitle="Jumlah"></line-chart>
          </div>
        </div>
      </section>
    </template>
    <template #fallback>
      Chart is loading...
    </template>
  </Suspense>
</template>

<script>
import {onMounted, ref} from "vue";
import axios from "axios";

export default {
  name: "ChartKerjasamaYear",
  setup() {
    let data = ref([]);

    onMounted(() => {
      getData();
    });

    const getData = async () => {
      const url = `${process.env.VUE_APP_API_URL}kerjasama/chart-kerjasama-by-year`;
      await axios
          .get(url)
          .then((res) => {
            const datas = res.data.data;
            data.value = datas.map(({nama, jumlah}) => [nama, jumlah]).reverse();
          })
          .catch((err) => {
            console.info("error", err);
          });
    }

    return {data}
  }
}
</script>

<style lang="scss" scoped>
.hero{
  margin-top: 20px;
}

.title {
  margin-left: 5px;

  @media (max-width: 769px) {
    font-size: larger;
  }
}

.kanan {
  margin-left: 5px;
  background-color: $keempat;
  width: 70%;

  @media (max-width: 769px) {
    width: 100%;
  }
}
</style>
