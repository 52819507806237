<template>
  <section class="box" data-aos="fade-right" data-aos-duration="3000">
    <div class="container search-home">
      <div class="column is-offset-1">
        <h1 class="title has-text-centered">Cari Mitra Kerjasama</h1>
        <div class="field">
          <div class="control columns">
            <input
                class="input is-large"
                type="text"
                placeholder="Masukkan mitra kerjasama"
                v-model="searchKata"
                @keyup.enter="cariKata"
            />
            <button
                class="button is-large ml-2 kelima search-home-button"
                @click="cariKata"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useRouter} from "vue-router";
import {reactive, toRefs} from "vue";

export default {
  name: "Search",
  setup() {
    const router = useRouter();
    const query = reactive({
      searchKata: "",
    });

    const cariKata = () => {
      router.push({name: "Kerjasama", query: {search: query.searchKata}});
    };

    return {...toRefs(query), cariKata};
  },
};
</script>

<style lang="scss" scoped>
.title {
  @media (max-width: 769px) {
    font-size: larger;
  }
}

@media (max-width: 769px) {
  .search-home-button {
    margin-top: 5px;
    width: 95%;
  }
}

.search-home {
}
</style>
