import {createStore, createLogger} from "vuex";
import mahasiswaAsing from "./modules/MahasiswaAsing";
import kerjasama from "./modules/Kerjasama";

const debug = process.env.NODE_ENV !== "production";

const store = createStore({
    modules: {mahasiswaAsing, kerjasama},
    strict: debug,
    plugins: debug ? [createLogger()] : [],
});

export default store;
