<template>
  <chart-kerjasama-year />

  <search />

  <chart-kerjasama-pie />

  <counter />

  <chart-mahasiswa-by-country />
</template>

<script>
import Search from "../components/layouts/Search";
import Counter from "../components/layouts/Counter";
import ChartKerjasamaYear from "../components/layouts/ChartKerjasamaYear";
import ChartKerjasamaPie from "../components/layouts/ChartKerjasamaPie";
import ChartMahasiswaByCountry from "../components/layouts/ChartMahasiswaByCountry";

export default {
  name: "Home",
  components: {ChartMahasiswaByCountry, ChartKerjasamaPie, ChartKerjasamaYear, Counter, Search },
};
</script>

<style lang="scss" scoped>
.content-paging {
  margin-top: 20px;
}
</style>
