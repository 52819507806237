<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Aplikasi Kerjasama</strong> by
        <a href="https://pustipanda.uinjkt.ac.id"
          >Pustipanda UIN Syarif Hidayatullah Jakarta</a
        >.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
a {
  color: $pertama;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $kedua;
  }
}
</style>
