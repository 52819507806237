<template>
  <Suspense>
    <template #default>
      <section class="hero is-small box">
        <h1 class="title">Mahasiswa Asing Berdasarkan Negara</h1>

        <div class="backgroud">
          <column-chart :data="data" :colors="['#08c7c0']" xtitle="Negara" ytitle="Jumlah"></column-chart>
        </div>
      </section>
    </template>
    <template #fallback>
      Chart is loading...
    </template>
  </Suspense>
</template>

<script>
import {onMounted, ref} from "vue";
import axios from "axios";

export default {
  name: "ChartMahasiswaByCountry",
  setup() {
    let data = ref([]);

    onMounted(() => {
      getData();
    });

    const getData = async () => {
      const url = `${process.env.VUE_APP_API_URL}kerjasama/chart-mahasiswa-asing-by-country`;
      await axios
          .get(url)
          .then((res) => {
            const datas = res.data.data;
            data.value = datas.map(({nama, jumlah}) => [nama, jumlah]);
          })
          .catch((err) => {
            console.info("error", err);
          });
    }

    return {data}
  }
}
</script>

<style lang="scss" scoped>

.hero{
  margin-top: 20px;
}
.title {
  @media (max-width: 769px) {
    font-size: larger;
  }
}
</style>
