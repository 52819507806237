<template>
  <section class="hero is-medium">
    <div class="hero-body has-text-centered">
      <h1>Oops, it looks like the page you're looking for doesn't exist.</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
