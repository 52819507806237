import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import aos from "aos";

import "bulma/bulma.sass";
import "aos/dist/aos.css";

import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'

import {createI18n} from "vue-i18n";
import id from "@/locales/id.json";
import en from "@/locales/en.json";

let curLocale = sessionStorage.getItem('locale');
if (!curLocale) {
    curLocale = 'id';
}

const i18n = createI18n({
    legacy: false,
    locale: curLocale,
    messages: {
        en,
        id,
    }
})

const app = createApp(App);
app.use(aos.init());
app.use(i18n);
app.use(store);
app.use(router);
app.use(VueChartkick);
app.mount("#app");
