<template>
  <Navigation />
  <div class="container">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import Navigation from "./Navigation";
import Footer from "./Footer";

export default {
  name: "Layout",
  components: { Footer, Navigation },
};
</script>
