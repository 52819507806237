<template>
  <suspense>
    <template #default>
      <section class="section is-large p-4">
        <h1 class="title">Mahasiswa Asing</h1>
        <h2 class="subtitle">
          Universitas Islam Negeri Syarif Hidayatullah Jakarta.
        </h2>

        <div class="content-form">
          <div class="level search">
            <input
              class="input is-medium input-search"
              v-model="query.search"
              @keyup.enter="cari"
            />
            <button class="button is-medium button-search" @click="cari">
              Cari
            </button>
          </div>
        </div>

        <div class="content-data">
          <div class="columns">
            <div class="column">Fakultas</div>
            <div class="column">Prodi</div>
            <div class="column">Nama</div>
            <div class="column is-1">Detail</div>
          </div>

          <mahasiswa-asing-item
            :mahasiswa="item"
            v-for="(item, index) in data.list"
            :key="index"
          />
        </div>

        <div class="content-paging">
          <nav
            class="pagination is-right"
            role="navigation"
            aria-label="pagination"
          >
            <button
              class="pagination-previous paging-button"
              @click="previousPage"
              :disabled="query.page === 0"
            >
              Previous
            </button>
            <button
              class="pagination-next paging-button"
              @click="nextPage"
              :disabled="query.page === query.totalPages"
            >
              Next page
            </button>
          </nav>
        </div>
      </section>
    </template>
    <template #fallback>
      <div class="pageloader is-active"><span class="title">Loading</span></div>
    </template>
  </suspense>
</template>

<script>
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import MahasiswaAsingItem from "../components/mahasiswa/MahasiswaAsingItem";

export default {
  name: "MahasiswaAsing",
  components: { MahasiswaAsingItem },
  setup() {
    const store = useStore();

    let query = reactive({
      page: 0, // computed(() => store.getters.mahasiswaAsingPagingPage),
      size: computed(() => store.getters.mahasiswaAsingPagingSize),
      totalElements: computed(
        () => store.getters.mahasiswaAsingPagingTotalElements
      ),
      totalPages: computed(
        () => store.getters.mahasiswaAsingPagingTotalPages - 1
      ),
      search: "",
    });

    onMounted(async () => {
      await store.dispatch("fetchAllMahasiswaAsings");
    });

    const cari = () => {
      //console.info("query", query.search);
      query.page = 0;
      store.dispatch("fetchAllMahasiswaAsings", query);
    };

    const data = computed(() => store.getters.mahasiswaAsingList);

    const previousPage = () => {
      query.page -= 1;
      store.dispatch("fetchAllMahasiswaAsings", query);
    };

    const nextPage = () => {
      query.page += 1;
      store.dispatch("fetchAllMahasiswaAsings", query);
    };

    return { query, data, cari, previousPage, nextPage };
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma";
@import "~bulma-pageloader";

@media (max-width: 769px) {
  .title {
    font-size: larger;
  }
  .subtitle{
    font-size: small;
  }
}

.content-form {
  .search {
    .input-search {
    }

    .button-search {
      background-color: $kelima;
      color: $keempat;
      margin-left: 5px;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: rgba(8, 199, 192, 0.8);
      }
    }

    @media (max-width: 769px) {
      .button-search {
        margin-top: 5px;
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

.content-data {
  margin-top: 20px;

  .columns {
    .column {
      background-color: $ketiga;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.content-paging {
  margin-top: 20px;

  .paging-button {
    background-color: $kelima;
    color: $keempat;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: rgba(8, 199, 192, 0.8);
    }
  }
}
</style>
