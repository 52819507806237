<template>
  <section class="section is-medium">
    <h1 class="title">{{ t('page.about.contact-us') }}</h1>
    <h2 class="subtitle">
      <p v-html="t('page.about.location')"></p>
    </h2>

    <div class="columns">
      <iframe
          class="gmap"
          src="https://maps.google.com/maps?hl=en&amp;coord=-6.306654398858812,106.75622480213019&amp;q=UIN%20Syarif%20Hidayatullah%2C%20Cempaka%20Putih%2C%20South%20Tangerang%20City%2C%20Banten%2C%20Indonesia+(UIN%20Syarif%20Hidayatullah%20Jakarta)&amp;ie=UTF8&amp;t=&amp;z=16&amp;iwloc=B&amp;output=embed"
      >
      </iframe>
    </div>
  </section>
</template>
<script>
import {useI18n} from "vue-i18n";

export default {
  name: "About",
  setup() {
    const {locale, t} = useI18n({
      useScope: 'global',
    });

    return {locale, t};
  }
};
</script>
<style lang="scss" scoped>
@media (max-width: 769px) {
  .title {
    font-size: larger;
  }
  .subtitle {
    font-size: small;
  }
}

.gmap {
  width: 100%;
  height: 450px;
}
</style>
