<template>
  <kerjasama-modal
    v-if="modalActive"
    :class="{ 'is-active': modalActive }"
    :kerjasama="kerjasama"
    v-on:close-modal="closeModal"
  />

  <div class="columns">
    <div class="column">{{ kerjasama.kategoriKerjasama }}</div>
    <div class="column">{{ kerjasama.mitra }}</div>
    <div class="column">{{ kerjasama.jenisKerjasama }}</div>
    <div class="column">{{ kerjasama.negara }}</div>
    <div class="column is-1">
      <i-eye class="iicon" @click="showModal" />
    </div>
  </div>
</template>

<script>
import IEye from "../../assets/svg/eye.svg";
import KerjasamaModal from "./KerjasamaModal";
import { ref } from "vue";

export default {
  name: "KerjasamaItem",
  components: { KerjasamaModal, IEye },
  props: ["kerjasama"],
  setup() {
    let modalActive = ref(false);

    const showModal = () => {
      modalActive.value = true;
    };

    const closeModal = () => {
      modalActive.value = !modalActive.value;
    };

    return { showModal, modalActive, closeModal };
  },
};
</script>

<style lang="scss" scoped>
.columns {
  @media (max-width: 769px) {
    border: 1px solid $ketiga;
    margin: 2px 0;
  }

  .column {
  }
}
</style>
