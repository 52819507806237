<template>
  <header>
    <nav
        class="navbar container"
        role="navigation"
        aria-label="main navigation"
    >
      <div class="navbar-brand">
        <router-link
            :to="{ name: 'Home' }"
            class="navbar-item navbar-item-logo"
        >
          <logo class="logo"/>
          <span class="logo-title">SIKERMA</span>
        </router-link>

        <a
            @click="isNavBarShown = !isNavBarShown"
            role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarMenu"
            :class="{ 'is-active': isNavBarShown }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
          id="navbarMenu"
          class="navbar-menu"
          :class="{ 'is-active': isNavBarShown }"
      >
        <div class="navbar-end">
          <router-link :to="{ name: 'Home' }" class="navbar-item"
          >{{ t('navigations.home') }}
          </router-link>
          <router-link :to="{ name: 'Kerjasama' }" class="navbar-item"
          >{{ t('navigations.mitra-kerjasama') }}
          </router-link>
          <router-link :to="{ name: 'MahasiswaAsing' }" class="navbar-item"
          >{{ t('navigations.mahasiswa-asing') }}
          </router-link>
          <router-link :to="{ name: 'About' }" class="navbar-item"
          >{{ t('navigations.about') }}
          </router-link>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <select name="locale" v-model="locale">
              <option value="id">ID</option>
              <option value="en">EN</option>
            </select>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import {ref, watch} from "vue";
import Logo from "@/assets/svg/mitra.svg";
import {useI18n} from 'vue-i18n';

export default {
  name: "Navigation",
  components: {Logo},
  setup() {
    let isNavBarShown = ref(false);

    const {locale, t} = useI18n({
      useScope: 'global',
    });

    watch(locale, (newVal, oldVal) => {
      sessionStorage.setItem('locale', newVal);
      console.info(oldVal);
    });

    return {isNavBarShown, locale, t};
  },
};
</script>

<style lang="scss" scoped>
header {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.navbar-item {
  color: $pertama;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $kedua;
  }
}

.navbar-item-logo {
  color: $kedua;

  .logo {
    margin-left: -40px;
    width: 112px;
    height: 28px;
  }

  .logo-title {
    font-weight: bold;
    margin-left: -25px;

    &:focus {
      outline: none;
    }

    &:hover {
      color: $pertama;
    }
  }
}
</style>
