<template>
  <section class="hero is-medium counter">
    <div class="hero-body">
      <div class="level">
        <div class="level-item has-text-centered">
          <div data-aos="zoom-in-up" data-aos-duration="3000">
            <p class="heading">Kerjasama Dalam Negeri</p>
            <vue3-autocounter
              class="title counter-value"
              :start-amount="0"
              :end-amount="data.dalamNegeri"
              :duration="4"
            />
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div data-aos="zoom-in-down" data-aos-duration="3000">
            <p class="heading">Kerjasama Luar Negeri</p>
            <vue3-autocounter
              class="title counter-value"
              :start-amount="0"
              :end-amount="data.luarNegeri"
              :duration="4"
            />
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div data-aos="zoom-in-up" data-aos-duration="3000">
            <p class="heading">Mahasiswa Asing</p>
            <vue3-autocounter
              class="title counter-value"
              :start-amount="0"
              :end-amount="data.mahasiswaAsing"
              :duration="4"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue3Autocounter from "vue3-autocounter";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Counter",
  components: { Vue3Autocounter },
  setup() {
    const store = useStore();

    onMounted(async () => {
      await store.dispatch("fetchStatistiks");
    });

    // const data = reactive({
    //   dalamNegeri: 45,
    //   luarNegeri: 70,
    //   mahasiswaAsing: 100,
    // });

    const data = computed(() => store.getters.statistikList);
    //console.info("datas", data);

    return { data };
  },
};
</script>

<style lang="scss" scoped>
.counter {
  color: $pertama;
  background-color: $ketiga;

  .counter-value {
    color: $kedua;
    font-size: 35px;
    font-weight: 700;
    margin: 0 0 4px;
    display: block;
  }
}
</style>
